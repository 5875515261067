import axios from "axios";
import router from '../router/index';
import { Message } from 'element-ui';

const request = axios.create({
  baseURL: "https://api.espadas.win/api",
});

const whitelist = ['/index', '/login', '/register'];

// 请求拦截器
request.interceptors.request.use(
  (request) => {
    if (whitelist.some(url => request.url.startsWith(url))) {
      return request;
    }

    const token = localStorage.getItem("token");
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
      return request;
    } else {
      console.error('没有提供有效的token');
      // 返回原始请求，不抛出错误
      return request;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    //console.log('error==>', error);
    const response = error.response;

    if (!response || !response.data) {
      return Promise.reject(error);
    }

    const errorCode = response.data.code;

    if (errorCode === 100001 || errorCode === 100002 || errorCode === 100003) {
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      // 检查当前路由是否已经是 /register
      if (router.currentRoute.path !== '/register') {
        // 只有在当前路由不是 /register 时才进行重定向
        router.push('/register');
      }
      // console.error('token失效');
      // 继续传递原始的错误
      // return Promise.reject(error);
    }
    else {
      Message.error(response.data.message);
    }

    // return Promise.reject(error);
    return false;
  }
);

export default request;
