<template>
  <div id="app">
    <!-- <img v-if="isMobile" class="share-img" src="@/assets/share-cover.png" />
    <router-view v-else /> -->
    <router-view />
  </div>
</template>

<script>
// let width=window.screen.width
// 	if(width>640){
// 		window.location.href = 'https://pcs.777mbet.com';

// 	}else{
// 		window.location.href = 'https://www.777mbet.com';
// 	}

export default {
  data() {
    return {
      isMobile: false,
    };
  },

  mounted() {
    let url = window.location.href;
    // let invite_code=this.getParam(url, "invite_code");
    // let url = 'https://l.instagram.com/?u=https://pcs.777mbet.com/#/RegistrAtion?invite_code=83BBB9C4&e=AT176k2EMJG5Y9J_FusAU_LjlyPj7Be-obWMvnAcvNsRYBv4PuTrkNPgKaVRsjGtnuEOxLDEj0mLxGDK0bf5i0gYplOH72z_bVcAcJ85GHHyzdLAjYRHqWQ';
    let params = this.queryURLParams(url);
    let invite_code = params ? params['invite_code'] : '';
    let card_code = params ? params['card_code'] : '';
    this.isMobile = this.isMobileDevice() || this.isMobileViewport();
    if (this.isMobile) {
      //手机或平板
      if(invite_code){
        window.location.href = 'https://www.espadas.win/pages/login/reg/reg?invite_code='+invite_code;
      }
      else if(card_code){
        window.location.href = "https://www.espadas.win/pages/card/card?card_code=" + card_code;
      }
      else{
        window.location.href = 'https://www.espadas.win/';
      }
    } else {
      // PC端
      if (invite_code) {
        localStorage.setItem('invite_code', invite_code);
      }
      if(card_code){
        localStorage.setItem('card_code', card_code);
      }
    }
  },

  methods: {
    //使用userAgent判断
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    // 使用媒体查询判断
    isMobileViewport() {
      return window.matchMedia("(max-width: 767px)").matches;
    },

    getParam(path, name) {
      var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
      if (reg.test(path))
      return unescape(RegExp.$2.replace(/\+/g, " "));
      // return "";
    },

    queryURLParams(url) {
      let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
      let parames = {}; // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2;
      });
      console.log(parames);
      return parames;
    }
  },
};
</script> 

<style scoped>
  #app {
    width: 100vw;
    height: 100vh;
  }

  .share-img {
    width: 100%;
    height: 100%;
  }
</style>
